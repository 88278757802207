import { Faculty } from './../../models/faculty.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateService {

  endpoint: string = "https://mu-oer.herokuapp.com/api"

  constructor(
    private http: HttpClient
  ) { }

  createFaculty(Faculty){
    return this.http.post<any>(`${this.endpoint}/faculty/store`, Faculty)
  }

  getFaculty(): Observable<Faculty[]>{
    return this.http.get<Faculty[]>(`${this.endpoint}/faculty`)
  }

  editFaculty(Faculty){
    return this.http.post<any>(`${this.endpoint}/faculty/update`, Faculty)
  }

  deleteFaculty(Faculty){
    return this.http.post<any>(`${this.endpoint}/faculty/delete`, Faculty)
  }


  

}
