import { Faculty } from './../models/faculty.model';
import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CreateService } from '../services/create/create.service';
import { AddFaculty, GetFaculties, EditFaculty, DeleteFaculty } from '../actions/create';

export class FacultyStateModel {
    facultys!: Faculty[];
    areFacultysLoaded!: boolean;
}

@State<FacultyStateModel>({
    name: 'facultys',
    defaults: {
        facultys: [],
        areFacultysLoaded: false,
    }
})


@Injectable()
export class FacultyState {
    
    constructor(private createService: CreateService, private router: Router){
    
    }
    @Selector()
    static getFacultys(state: FacultyStateModel) {
        return state.facultys;
    }

    @Selector()
    static areFacultysLoaded(state: FacultyStateModel){
        return state.areFacultysLoaded;
    }


    @Action(AddFaculty)
        add({getState, patchState}: StateContext<FacultyStateModel>, {payload}: AddFaculty) {
            return this.createService.createFaculty(payload).pipe(tap((result) => {
                console.log(result)
                const state = getState();
                patchState({
                    // facultys: [...state.facultys, result]
                });
            }),
            // catchError((err: HttpErrorResponse) => {
            //     alert("Something happened. Please try again.");
            //     return throwError(new Error(err.message));
            //   })
            
            
            );
        }

        @Action(GetFaculties)
        get({getState, setState}: StateContext<FacultyStateModel>) {
        return this.createService.getFaculty().pipe(
            tap(result => {
                console.log(result)
            const state = getState();
            setState({
                ...state,
                facultys: result,
                areFacultysLoaded: true
            });
            })
        );
        }

        @Action(EditFaculty)
        edit({getState, patchState}: StateContext<any>, {payload}: EditFaculty) {
            return this.createService.editFaculty(payload).pipe(tap((result) => {
                console.log(result)
                const state = getState();
                patchState({
                    // facultys: [...state.facultys, result]
                });
            }),
            // catchError((err: HttpErrorResponse) => {
            //     alert("Something happened. Please try again.");
            //     return throwError(new Error(err.message));
            //   })
            
            
            );
        }


        @Action(DeleteFaculty)
        delete({getState, patchState}: StateContext<any>, {payload}: DeleteFaculty) {
            return this.createService.deleteFaculty(payload).pipe(tap((result) => {
                console.log(result)
                const state = getState();
                patchState({
                    // facultys: [...state.facultys, result]
                });
            }),
            // catchError((err: HttpErrorResponse) => {
            //     alert("Something happened. Please try again.");
            //     return throwError(new Error(err.message));
            //   })
            
            
            );
        }
}
