import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  

  constructor(
    private auth: AuthService,
    private _store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
      const token = this._store.selectSnapshot(state => state.auth.token);
      req = req.clone({
        setHeaders: {
          Authorization:  `Bearer ${token}`
        }
      });
    return next.handle(req);
  }

}
