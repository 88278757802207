import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GetUploads } from 'src/app/shared/actions/upload.action';
import { Upload } from 'src/app/shared/models/upload.model';
import { UploadService } from 'src/app/shared/services/upload/upload.service';
import{ MessengerService } from 'src/app/shared/services/messenger/messenger.service'

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  id
  uploads$: Observable<Upload[]>

  
  content = "My Account"
  categories = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'}
  ];
  
  constructor(
    private store: Store,
    private _router: Router,
    private uploadService: UploadService,
    private messengerService: MessengerService 
    ) { 
    this.uploads$ = this.store.select(state => state.uploads.uploads.response);
  }
  
  ngOnInit(): void {
    this.store.dispatch(new GetUploads());
  }


  openResourceDetails(upload){
      this.id = upload._id
      let data;
      data = {
        resourceID: this.id 
      }
   this.uploadService.getOneResource(data).subscribe(info =>{
     console.log(info)
     this.messengerService.send.next(info)
   })
  console.log(upload._id)
  }

}
