import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onlinestore',
  templateUrl: './onlinestore.component.html',
  styleUrls: ['./onlinestore.component.scss']
})
export class OnlinestoreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
