import { NotificationService } from './../notification/notification.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';
import { User } from  '../auth/user'
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';




@Injectable({
  providedIn: 'root'
})


export class AuthService {
  endpoint: string = "https://mu-oer.herokuapp.com/api"
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  currentUser = {};
 
  constructor(
      private http: HttpClient,
      public router: Router,
      private ngxLoader: NgxUiLoaderService,
      private notificationService: NotificationService,
      private store: Store
    ) { }

    // sign-up
    signUp (user: User): Observable<any>{
      let api = `${this.endpoint}/register`;
      return this.http.post(api, user)
      .pipe(
        catchError(this.handleError)
      )
    };

    // SignIN
    signIn(user: User){
      return this.http.post<any>(`${this.endpoint}/login`, user)
    }

    getToken(){
      return localStorage.getItem('access_token');
    }

    get isLoggedIn(): boolean {
      let authToken = localStorage.getItem('access_token');
      return (authToken !== null) ? true : false 
    }

    doLogOut(){
      let removeToken = localStorage.removeItem('access_token');
      if (removeToken == null){
        this.router.navigate(['login'])
      }
    }

 // User profile
  // getUserProfile(id): Observable<any> {
  //   let api = `${this.endpoint}/user-profile/${id}`;
  //   return this.http.get(api, { headers: this.headers }).pipe(
  //     map((res) => {
  //       return res || {}
  //     }),
  //     catchError(this.handleError)
  //   )
  // }


  isAuthenticated() {
    let bool: boolean;
    const token = !!this.store.selectSnapshot(
      ourState => ourState.auth.token
    );

    if(token) {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  }



    // Error 
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      msg = error.error.message;
    } else {
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }

}
