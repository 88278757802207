<div style="text-align: center; margin: 15px 0px;">
    <div style="font-weight:500; font-size: 32px;">Online Book Store</div>
</div>

<div class="single-book" style="margin-top:4%;">
    <div class="row">
        <div class="col-md-5 col-12"  style="text-align:-webkit-center !important; margin: 0px !important;">
            <div class="jumbotron jumbotron-fluid b1 jumb1" style="background-color:#F2ECEC; width: 30rem; text-align: center; height: 35rem;">
                <img class="img-fluid" style="width:55%" src="assets/images/single-img.svg" alt="">
            </div>
        </div>
        <div class="col-md-7 col-12">
            <div style="font-size: 23px;">Dear Paris <span style="font-style:italic;">(a love story)</span></div>
            <div style="display: flex;" class="mt-2">
                <div style="margin-right:30px">Tary. M. Sunny</div>
                <div>Year: 2020</div>
            </div>
            <div>
                <mdb-card class="mt-5">
                    <mdb-card-body style="background-color:#F2ECEC;">
                      <div style="display:flex">
                          <div class="mr-3"><img src="assets/images/e-book.svg" alt=""></div>
                          <div>E-Book</div>
                      </div>
                    </mdb-card-body>
                </mdb-card>

                <mdb-card class="mt-3">
                    <mdb-card-body>
                        <div style="display:flex;" class="">
                            <div class="pt-2 price" style="color:#fb9323; font-size: 25px; font-weight: 500; margin-right:10%">$9.99</div>
                            <div class="m-btn"><a class="m-btn" style="padding: 7px 30px;margin-right: 30px; background-color:#00356b !important; color:#fff; border: none;" class="btn btn-primary" role="button" data-mdb-toggle="button">Buy Now</a></div>
                            <div class="m-btn"><a style="padding: 7px 30px;margin-right: 30px; background-color:#b5861e !important; color:#fff; border: none;" class="btn btn-primary" role="button" data-mdb-toggle="button">Add to Cart</a></div>
                        </div>
                    </mdb-card-body>
                </mdb-card>
            </div>

            <div class="mt-4 abstract">
                <h3 style="font-weight: 500;">Abstract/Review</h3>
                <p style="font-size: 16px; line-height: 22px; color:#00356B">
                    Be transported to the banks of the Seine, a corner boulangerie, or beneath the Eiffel Tower with these beautifully 
                    illustrated vignettes of life in the City of Light. What began as a way to fund travel became ten years of a letter 
                    subscription service delivering thousands of painted letters to subscribers who delight in fun mail
                </p>
                <p style="font-size:16px; line-height:22px; color:#00356B">
                    Eat, Pray, Love meets Claude Monet in this epistolary ode to Paris. What started as a whim in a Latin Quarter café 
                    blossomed into Janice MacLeod’s yearslong endeavor to document and celebrate life in Paris, sending monthly snippets of her 
                    paintings and writings to the mailboxes of ardent followers around the world. Now, Dear Paris collects the entirety of the 
                    Paris Letters project:
                </p>
            </div>
        </div>

    </div>
    <div class="suggested-books" style=" width:100%; margin-top: 20px; border-top: 1px solid rgb(216, 210, 210);">
        <h3>Suggested books</h3>
        <p>Based on your selections and actions</p>

        <div class="home-cards mb-3" id="windows">
            <div>
                <div class="jumbotron jumbotron-fluid b1" style="background-color:#F2ECEC; width: 11rem; padding: 2rem 2rem; text-align: center; height: 15rem;">
                    <img style="width:7rem" src="assets/images/book14.png" alt="book-3">
                </div>
                <h3 style="font-weight: 500; margin-bottom: 10px !important; margin-bottom: 2px !important;color:#00356B">Dear Paris</h3>
                <p style=" margin-bottom: 3px !important; color:#585454">Bejamin Ken</p>
                <p>Year: 2020</p> 
            </div>

            <div>
                <div class="jumbotron jumbotron-fluid b1" style="background-color:#F2ECEC; width: 11rem; padding: 2rem 2rem; text-align: center; height: 15rem;">
                    <img style="width:7rem" src="assets/images/book-3.svg" alt="book-3">
                </div>
                <h3 style="font-weight: 500; margin-bottom: 10px !important;  margin-bottom: 2px !important;color:#00356B">Dear Paris</h3>
                <p style=" margin-bottom: 3px !important;">Bejamin Ken</p>
                <p>Year: 2020</p> 
            </div>

            <div>
                <div class="jumbotron jumbotron-fluid b1" style="background-color:#F2ECEC; width: 11rem; padding: 2rem 2rem; text-align: center; height: 15rem;">
                    <img style="width:7rem" src="assets/images/book12.svg" alt="book-3">
                </div>
                <h3 style="font-weight: 500; margin-bottom: 10px !important;  margin-bottom: 2px !important;color:#00356B">Dear Paris</h3>
                <p style=" margin-bottom: 3px !important;">Bejamin Ken</p>
                <p>Year: 2020</p> 
            </div>

            <div>
                <div class="jumbotron jumbotron-fluid b1" style="background-color:#F2ECEC; width: 11rem; padding: 2rem 2rem; text-align: center; height: 15rem;">
                    <img style="width:7rem" src="assets/images/book-3.svg" alt="book-3">
                </div>
                <h3 style="font-weight: 500; margin-bottom: 10px !important;  margin-bottom: 2px !important;color:#00356B ">Dear Paris</h3>
                <p style=" margin-bottom: 3px !important;">Bejamin Ken</p>
                <p>Year: 2020</p> 
            </div>

            <div>
                <div class="jumbotron jumbotron-fluid b1" style="background-color:#F2ECEC; width: 11rem; padding: 2rem 2rem; text-align: center; height: 15rem;">
                    <img style="width:7rem" src="assets/images/book13.svg" alt="book-3">
                </div>
                <h3 style="font-weight: 500; margin-bottom: 10px !important;  margin-bottom: 2px !important;color:#00356B">Dear Paris</h3>
                <p style="margin-bottom: 3px !important;;">Bejamin Ken</p>
                <p>Year: 2020</p> 
            </div>

            <div>
                <div class="jumbotron jumbotron-fluid b1" style="background-color:#F2ECEC; width: 11rem; padding: 2rem 2rem; text-align: center; height: 15rem;">
                    <img style="width:7rem" src="assets/images/book-3.svg" alt="book-3">
                </div>
                <h3 style="font-weight: 500; margin-bottom: 10px !important;  margin-bottom: 2px !important;">Dear Paris</h3>
                <p style=" margin-bottom: 3px !important;">Bejamin Ken</p>
                <p>Year: 2020</p> 
            </div>
        </div>
    </div>
</div>