import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-oer-footer',
  templateUrl: './oer-footer.component.html',
  styleUrls: ['./oer-footer.component.scss']
})
export class OerFooterComponent implements OnInit {
  test : Date = new Date();
  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
  }

}
