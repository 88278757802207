import { UploadService } from './../../shared/services/upload/upload.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GetUploads } from 'src/app/shared/actions/upload.action';
import { Upload } from 'src/app/shared/models/upload.model'
import { MessengerService } from 'src/app/shared/services/messenger/messenger.service'


@Component({
  selector: 'app-oer-single-resource',
  templateUrl: './oer-single-resource.component.html',
  styleUrls: ['./oer-single-resource.component.scss']
})
export class OerSingleResourceComponent implements OnInit {

    singleResource$: any

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private location: Location,
    private upload: UploadService,
    private message: MessengerService

  ) {
  }

  ngOnInit(): void {
   this.message.send.subscribe(data => { this.singleResource$ = data || localStorage.get('info'); 
     
   })
  }

}
