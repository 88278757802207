import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Component, ErrorHandler, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { MustMatch } from 'src/app/shared/services/auth/must-match-validator';
// import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { Store } from '@ngxs/store';
import { Register } from 'src/app/shared/actions/auth.action';

@Component({
  selector: 'app-oer-register',
  templateUrl: './oer-register.component.html',
  styleUrls: ['./oer-register.component.scss']
})
export class OerRegisterComponent implements OnInit {

  public submitted = false; 
  signupForm!: FormGroup;
  value

  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private notifyService: NotificationService,
    public _store: Store
    // private SpinnerService: NgxSpinnerService,
    ) {
    }
    
    ngOnInit() {
      this.signupForm = this.fb.group({
        firstName: ['', Validators.compose([Validators.required])],
        lastName: ['', Validators.compose([Validators.required])],
        email: ['', Validators.compose([Validators.required])],
        password: ['', Validators.compose([Validators.required])],
        confirmPassword: ['', Validators.compose([Validators.required])]
      }, {
        validator: MustMatch('password', 'confirmPassword')
      })
    }
    
    get formControl() {
      return this.signupForm.controls;
    }
    
    
    registerUser() {
      this.submitted = true;
      console.log(this.signupForm.value)
          if(this.signupForm.invalid){
            return;
          }else{
            const { firstName, lastName, email, password } = this.signupForm.value
            this.value = {
              name: firstName + ' ' + lastName, 
              email, 
              password
            }
          this._store.dispatch(new Register(this.value)).subscribe((info) => {
          if(info){
            console.log(info)
            this.router.navigate(['/login'])
            this.notifyService.showSuccess(info.message, "Success")
          }else{
            this.notifyService.showWarning(info.message, "Error")
          }
        },err =>{
          // this.SpinnerService.hide();
          let error = err.error
          this.notifyService.showWarning(error.info, "Error")
          console.log(err)
        })
        
      }
    }
}