import { User } from './../../shared/services/auth/user';
import { Login } from './../../shared/actions/auth.action';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { AuthService } from './../../shared/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-oer-login',
  templateUrl: './oer-login.component.html',
  styleUrls: ['./oer-login.component.scss']
})
export class OerLoginComponent implements OnInit {

  signinForm!: FormGroup;
  public submitted = false;  
  LoginButton: boolean = true;
  constructor(
    private authService: AuthService,
    public fb: FormBuilder,
    private _store: Store,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private notification: NotificationService,
    ){}
  
  ngOnInit() {

    this.signinForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    }) 
  
  }

  get formControl() {
    return this.signinForm.controls;
  }

  loginUser() :void{
    this._store.dispatch(new Login(this.signinForm.value)).subscribe((data) => {
      console.log(data.auth)
      const message = data.auth.message;  
      if(data.auth.token !== undefined && data.auth.role == "user"){
        this.router.navigate(['/Dashboard']);
        this.notification.showSuccess(message, "Success")
      }else if(data.auth.token !== undefined && data.auth.role == "superadmin"){
        this.router.navigate(['/admin']);
        this.notification.showSuccess(message, "Success")
        location.reload()
      }else if(data.auth.token !== undefined && data.auth.role == "admin"){
        this.router.navigate(['/Dashboard']);
        this.notification.showSuccess(message, "Success")
      }else{
        this.notification.showWarning(message, "Error")
        location.reload()
        console.log("error")
      }
    },err => {
      console.log(err)
      this.notification.showWarning(err.message, "Error")
      // location.reload()
      // this.router.navigate(['/login']);
    });
  }

  }
