<div style="text-align: center; margin: 15px 0px;">
    <div style="font-weight:500; font-size: 32px;">Online Book Store </div>
    <div style="margin-top:8px">You can buy books  from our publishing  stores</div>
</div>
  <!-- search bar -->
  <div>
    <ul class="nav justify-content-center white lighten-4" style="padding-bottom: 1%;">
      <div class="search-div">
         
          <input class="border-right form-control form-control-sm mr-3 ml-5 w-75" type="text" placeholder="Search Library Resources" aria-label="Search"
          mdbInput> 
          <div class="input-btn" style="float: right; margin-top: -40px; margin-right: 100px;">
              <button class="btn-search-all" mat-button>All</button><button class="btn-search-author" mat-button>Author</button><button class="btn-search-title" mat-button>Title</button>
          </div>
  
      </div>
      <mdb-icon class="search-ico" fas icon="search" aria-hidden="true"></mdb-icon>
    </ul> 
  </div>

  <!-- body -->

    <div class="store-container">
      <!-- bottons -->
        <div style="display: flex; margin-left:35px; margin-bottom:20px" class="m-search">
            <div style="color:#00356B; background-color: #f2ecec;; padding:5px 25px; margin-right:30px; border-radius: 40px; cursor:pointer">Author</div>
            <div style="color:#00356B; background-color: #f2ecec;; padding:5px 25px; margin-right:30px; border-radius: 40px;cursor: pointer;">Format</div>
            <div style="color:#00356B; background-color: #f2ecec;; padding:5px 25px; margin-right:30px; border-radius: 40px; cursor: pointer;">Language</div>
            <div style="color:#00356B; background-color: #f2ecec;; padding:5px 25px; margin-right:30px; border-radius: 40px; cursor: pointer;">book series</div>
        </div>
        <div class="row mb-5">
            <div class="col-lg-8" style="display: flex;">
                <div class="row resp" style="text-align: -webkit-center;">
                    <div class="col-md-4" style="justify-content: center !important;" >
                        <div  class="jumbotron jumbotron-fluid b1" style="background-color:#F2ECEC; width: 15rem; text-align: center; height: 20rem;">
                            <img src="assets/images/book-1.svg" alt="book-1">
                         </div>
                        <h3 style="font-weight: 500; margin-bottom: 10px !important;">The Story of Alice</h3>
                        <p>Benjamin Graham</p>
                        <p>Year: 2020</p>
                    </div>
                    <div class="col-md-4">
                        <div class="jumbotron jumbotron-fluid mr-5 b1 b2" style="background-color:#F2ECEC; width: 15rem; height: 20rem; text-align: center;">
                            <img src="assets/images/book-2.svg" alt="book-2">
                        </div>
                        <h3 style="font-weight: 500; margin-bottom: 10px !important;">The Kings of Drug </h3>
                        <p>Nora Baret</p>
                        <p>Year: 2020</p>
                    </div>
                    <div class="col-md-4">
                        <div class="jumbotron jumbotron-fluid b1" style="background-color:#F2ECEC; width: 15rem; text-align: center; height: 20rem;">
                            <img src="assets/images/book-3.svg" alt="book-3">
                        </div>
                        <h3 style="font-weight: 500; margin-bottom: 10px !important;">Dear Paris</h3>
                        <p>Bejamin Ken</p>
                        <p>Year: 2020</p>  
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="card">
                    <div class="card-body" style="background-color: #f2ecec;">
                        <h3 style="font-weight:500 ;">Feature Books</h3>
                        <mdb-card>
                         <mdb-card-body style="height:115px;">
                           <mdb-card-text> 
                              <div style="display: flex;">
                                 <div><img style="width:55px" src="assets/images/book-1.svg" alt=""></div>
                                 <div class="ml-3">
                                    <p style="margin: 0px 0px 8px;">The Kings of Drug</p>
                                    <p style="margin: 0px 0px 8px;">Nora Baret</p>
                                    <p>Year: 2020</p>
                                 </div>
                              </div>
                            </mdb-card-text>
                         </mdb-card-body>
                       </mdb-card>
                    </div>

                    <div class="card-body" style="background-color: #f2ecec;">
                        <mdb-card>
                         <mdb-card-body style="height:115px;">
                           <mdb-card-text> 
                              <div style="display: flex;">
                                 <div><img style="width:55px" src="assets/images/book-2.svg" alt=""></div>
                                 <div class="ml-3">
                                    <p style="margin: 0px 0px 8px;">The Kings of Drug</p>
                                    <p style="margin: 0px 0px 8px;">Nora Baret</p>
                                    <p>Year: 2020</p>
                                 </div>
                              </div>
                            </mdb-card-text>
                         </mdb-card-body>
                       </mdb-card>
                    </div>

                    <div class="card-body" style="background-color: #f2ecec;">
                        <mdb-card>
                         <mdb-card-body style="height:115px;">
                           <mdb-card-text> 
                              <div style="display: flex;">
                                 <div><img style="width:55px" src="assets/images/book-3.svg" alt=""></div>
                                 <div class="ml-3">
                                    <p style="margin: 0px 0px 8px;">The Kings of Drug</p>
                                    <p style="margin: 0px 0px 8px;">Nora Baret</p>
                                    <p>Year: 2020</p>
                                 </div>
                              </div>
                            </mdb-card-text>
                         </mdb-card-body>
                       </mdb-card>
                    </div>
            </div>
        </div>
      </div>
    </div>

    <!-- recent books -->

  <div class="recent-book">
      <h3 style="color:#fff; padding:20px; margin-left:30px; font: 500; font-size: 20px;">Recent Books</h3>
    <div class="row" style="text-align: -webkit-center;">
          <div class="col-md-3" style="justify-content: center !important;" >
              <div class="jumbotron jumbotron-fluid b1 img-fluid" style="background-color:#F2ECEC; width: 15rem; text-align: center; height: 20rem;">
                  <img src="assets/images/book-1.svg" alt="book-1">
                </div>
              <h3 style="font-weight: 500; margin-bottom: 10px !important; color:#b5861e">The Story of Alice</h3>
              <p style="color:#fff">Benjamin Graham</p>
              <p style="color:gray">Year: 2020</p>
          </div>
          <div class="col-md-3">
              <div class="jumbotron jumbotron-fluid mr-5 b1 b2 img-fluid" style="background-color:#F2ECEC; width: 15rem; height: 20rem; text-align: center;">
                  <img src="assets/images/book-2.svg" alt="book-2">
              </div>
              <h3 style="font-weight: 500; margin-bottom: 10px !important; color:#b5861e">The Kings of Drug </h3>
              <p style="color:#fff">Nora Baret</p>
              <p style="color:gray">Year: 2020</p>
          </div>
          <div class="col-md-3">
              <div class="jumbotron jumbotron-fluid b1 img-fluid" style="background-color:#F2ECEC; width: 15rem; text-align: center; height: 20rem;">
                  <img src="assets/images/book-3.svg" alt="book-3">
              </div>
              <h3 style="font-weight: 500; margin-bottom: 10px !important; color:#b5861e">Dear Paris</h3>
              <p style="color:#fff">Bejamin Ken</p>
              <p style="color:gray">Year: 2020</p>  
          </div>  
          <div class="col-md-3">
              <div class="jumbotron jumbotron-fluid b1 img-fluid" style="background-color:#F2ECEC; width: 15rem; text-align: center; height: 20rem;">
                  <img src="assets/images/book-3.svg" alt="book-3">
              </div>
              <h3 style="font-weight: 500; margin-bottom: 10px !important; color:#b5861e">Dear Paris</h3>
              <p style="color: #fff;">Bejamin Ken</p>
              <p style="color:gray">Year: 2020</p>  
          </div>
      </div>
  </div>

    <!-- News -->
    <div class=" store-container mt-5 mb-5">
        <div class="row pt-2 mb-3">
            <h2 class="col-md-12 news-heading py-3">News</h2>
              <div class="card-deck">
                  <!-- Card -->
                  <mdb-card>
                    <div class="view overlay waves-light" mdbWavesEffect>
                      <!-- Card img -->
                      <mdb-card-img class="img-fluid rounded" src="assets/images/Rectangle-30.png" alt="Card image cap"></mdb-card-img>
                      <a>
                        <div class="mask rgba-white-slight"></div>
                      </a>
                    </div>
                    <!--Card content-->
                    <mdb-card-body>
                      <!--Title-->
                      <mdb-card-title>
                        <h4 style="color: #5F5959;">10th June 2020</h4>
                      </mdb-card-title>
                
                      <!--Text-->
                      <mdb-card-text><div style="color:#00356B; font-weight: 600;">Research on the output being conveyed on the conference materials under the supervision of the FG.</div>
                      </mdb-card-text>
                
                      <a style="color:#F97C7C;" href="#" class="float-right" >read more...</a>
                    </mdb-card-body>
                  </mdb-card>
                
                  <!-- Card -->
                  <mdb-card>
                    <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                      <!-- Card img -->
                      <mdb-card-img src="assets/images/Rectangle-31.png" alt="Card image cap"></mdb-card-img>
                      <a>
                        <div class="mask"></div>
                      </a>
                    </div>
                    <!--Card content-->
                    <mdb-card-body>
                      <!--Title-->
                      <mdb-card-title>
                        <h4 style="color: #5F5959;">10th June 2020</h4>
                      </mdb-card-title>
                
                      <!--Text-->
                      <mdb-card-text><div style="color:#00356B; font-weight: 600;">Research on the output being conveyed on the conference materials under the supervision of the FG.</div>
                      </mdb-card-text>
                
                      <a style="color:#F97C7C;" href="#" class="float-right" >read more...</a>
                    </mdb-card-body>
                  </mdb-card>
                
                  <!-- Card -->
                  <mdb-card>
                    <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                      <!-- Card img -->
                      <mdb-card-img src="assets/images/Rectangle-32.png" alt="Card image cap"></mdb-card-img>
                      <a>
                        <div class="mask"></div>
                      </a>
                    </div>
                    <!--Card content-->
                    <mdb-card-body>
                      <!--Title-->
                      <mdb-card-title>
                        <h4 style="color: #5F5959;">10th June 2020</h4>
                      </mdb-card-title>
                
                      <!--Text-->
                      <mdb-card-text><div style="color:#00356B; font-weight: 600;">Research on the output being conveyed on the conference materials under the supervision of the FG.</div>
                      </mdb-card-text>
                
                      <a style="color:#F97C7C;" href="#" class="float-right" >read more...</a>
                    </mdb-card-body>
                  </mdb-card>
                </div>
          </div> 
    </div>