import { Store } from '@ngxs/store';
import { Logout } from './../../actions/auth.action';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-oer-header',
  templateUrl: './oer-header.component.html',
  styleUrls: ['./oer-header.component.scss']
})
export class OerHeaderComponent implements OnInit {
  
  constructor(
    public router: Router,
    public authService: AuthService,
    public store: Store
  ) { }

  ngOnInit(): void {
    
  }
  
  logOut(){
    this.store.dispatch(new Logout()).subscribe(success => {
      this.router.navigate(['/login']);
    }, error => {});
  }

}

