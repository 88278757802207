<div class="login-container pb-3" style="margin-top:3%;" ngxUiLoaderBlurred>
    <div class="row" style="margin-right: 10px; border-radius: 15px; justify-content: center !important;;">
      <div class="col-md-6" style="margin-top:6%;">
          <div style="margin-left: 150px;">
          </div>
          <div>
              <p class="login-welcome">
                  Welcome To Maduka University Open Education Resource
                  
              </p>
          </div>
          <div>
              <img class="img-fluid" src="assets/images/login-image.png" height="" alt="">
          </div>
      </div>
      <div class="col-md-4" style="background: #00356B; border-radius: 0px 15px 15px 0px; padding:0px 50px">
        <div class="mt-4" style="text-align: center !important;">
          <img style="width: 20%; height: auto;" src="assets/images/logo/Sam.png" height="100" alt="">
        </div>
        <!-- Material form login -->
        <h5 class="login-title text-white">
            Login to your Account
        </h5>
        <!--Card content-->
        <mdb-card-body>
            <!-- Form -->
            <form class="text-center" style="color: #0F0D3E;"  [formGroup]="signinForm" (ngSubmit)="loginUser()">
              <!-- Email -->
              <!-- <input style="background-color: #f4f6f9;" type="text" name="email" mdbInput mdbValidation type="email" id="form8" class="form-control" formControlName="email"/> -->
                <!-- <input [(ngModel)]="LoginData.email" name="email" mdbInput mdbValidation type="email" id="email" class="form-control" formControlName="email"> -->
                <div class="md-form">
                  <!-- <label>First Name</label>> -->
          
                  <div class="form-outline">
                    <!-- <label class="form-label">First Name</label> -->
                    <input style="background-color: #f4f6f9; border-radius: 5px;" name="email" mdbInput type="email" id="form8" placeholder="Email Address" class="form-control pl-2" formControlName="email" [ngClass]="{ 'is-invalid': submitted && formControl.email.errors }" />
                    <div *ngIf="submitted && formControl.email.errors" class="invalid-feedback">
                      <div *ngIf="formControl.email.errors.required">Email is required</div>
                      <div *ngIf="formControl.email.errors.email">Email must be a valid email address</div>
                  </div>
                  </div>
                </div>
              <!-- Password -->
              <div class="md-form">
                
              </div>
              <div class="md-form">
                <input style="background-color: #f4f6f9; border-radius: 5px;" name="email" mdbValidation name="password" type="password" id="password" placeholder="password" class="form-control pl-2" mdbInput formControlName="password" [ngClass]="{ 'is-invalid': submitted && formControl.password.errors }"/>
                <div *ngIf="submitted && formControl.password.errors" class="invalid-feedback">
                  <div *ngIf="formControl.password.errors.required">Password is required</div>
                  <div *ngIf="formControl.password.errors.minlength">Password must be at least 6 characters</div>
              </div>
              </div>
        
              <div class="d-flex justify-content-around text-white">
                <div>
                  <!-- Remember me -->
                  <mdb-checkbox>Remember me</mdb-checkbox>
                </div>
                <div>
                  <!-- Forgot password -->
                  <a  class="text-white" [routerLink]="[ '/#']">Forgot password?</a>
                </div>
              </div>
        
              <!-- Sign in button -->
              <button mdbBtn class="custom-submit my-4 waves-effect z-depth-0" mdbWavesEffect type="submit">Login</button>
              
        
              <!-- Register -->
              <div style="display:flex; justify-content: space-between !important;">
                <div><p class="text-white" >Don't have an account?</p></div>
                <div><a class="text-white"  [routerLink]="[ '/register']">REGISTER</a></div>
              </div>
             
            </form>
            <!-- Form -->
          </mdb-card-body>
          <!-- Material form login -->
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  <p style="color: white">Please Wait. </p>  
</ngx-spinner>

