import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-oer',
  templateUrl: './oer.component.html',
  styleUrls: ['./oer.component.scss']
})
export class OerComponent implements OnInit {
  visible:boolean=false;
  constructor(
    public router: Router,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {

  }

}
