<div class="profile-container mt-5">
    <div class="row">
        <div class="col-md-9">
            <div style="display: flex;">
                <div>
                    <img style="width:80%; border-radius: 10px;" src="assets/images/eze.svg" alt="">
                </div>
                <div style="margin-right:10%;">
                    <h3 style="font-size: 25px; margin-bottom: 15px !important;font-weight: 550;">KENNETH CHIDERA EZE</h3>
                    <p style="color: #00356B; margin-bottom: 10px !important;">Department: <span style=" font-size: 15px;margin-left:4.5%; color: #00356B;">Mechanical Engineering</span></p>
                    <p style="color: #00356B; margin-bottom: 10px !important;">Email: <span style="margin-left:11%">Kennet@radaa.com</span></p>
                    <p style="color: #00356B; margin-bottom: 15px !important;">Gender: <span style="margin-left:9%">Male</span></p>
                    <div style="display: flex;">
                        <div style="border-right:1px solid blue; padding-right:15px"><button style="background-color: #b5861e; color: #fff;" type="button" class="btn btn"><span style="margin-right:20px"><i class="fas fa-pen"></i></span>EDIT</button></div>
                        <div style="font-size:20px; padding:10px; padding-left:15px"><span><i class="far fa-file"></i> CV</span></div>
                    </div>
                </div>
            </div>
            <div style="display: inline-block; width:100%;" class="mt-5 swicher">
                <mdb-card class="mb-3">
                    <mdb-card-body style="padding: 0px !important;">
                        <mdb-card-text>
                            <ul class="nav border m-auto" style="color:#00356B">
                                <li class="nav-item border-left" *ngFor="let menuList of menuLists" >
                                    <a style="margin-right:0px" [ngClass]="{'active': selectedList===menuList}" class="nav-link" (click)="openMenuList(menuList)">{{menuList}}</a>
                                </li>
                            </ul>

                            <div>
                                <div *ngIf="selectedList === 'Biography'" >
                                    <p style="margin: 15px 30px; font-size: 20px;">
                                        What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a 
                                        has?In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on 
                                    </p>

                                    <p style="margin: 15px 30px; font-size: 20px;">
                                        relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. Wikipedia
                                    </p>
                                </div>
                
                                <div *ngIf="selectedList === 'Membership'" >
                                    <p style="margin: 15px 30px; font-size: 20px;">
                                        What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a 
                                        galley of design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on 
                                    </p>

                                    <p style="margin: 15px 30px">
                                        meaningful content. Lorem final copy is available. Wikipedi
                                        relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. Wikipedia
                                    </p>
                                </div>
                                <div *ngIf="selectedList === 'publications'" >
                                    <p style="margin: 15px 30px; font-size: 20px;">
                                        he printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a 
                                        galley of type and scrambled it to make a type specimen book it has?In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on 
                                    </p>

                                    <p style="margin: 15px 30px; font-size: 20px;">
                                        meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. Wikipedi
                                        relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. Wikipedia
                                    </p>
                                </div>
                                <div *ngIf="selectedList === 'Specialization'" >
                                    <p style="margin: 15px 30px; font-size: 20px;">
                                        What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a 
                                        galolder text commonly used to demonstrate the visual form of a document or a typeface without relying on 
                                    </p>

                                    <p style="margin: 15px 30px; font-size: 20px;">
                                        meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. Wikipedi
                                        relying before final copy is available. Wikipedia
                                    </p>
                                </div>
                                <div *ngIf="selectedList === 'Research'" >
                                    <p style="margin: 15px 30px; font-size: 20px;">
                                        book it has?In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on 
                                    </p>

                                    <p style="margin: 15px 30px; font-size: 20px;">
                                        meaningful content. Lorem copy is available. Wikipedi
                                        relying on meaningful content.before final copy is available. Wikipedia
                                    </p>
                                </div>
                                <div *ngIf="selectedList === 'Responsibility'">
                                    <p style="margin: 15px 30px; font-size: 20px;">
                                       t has?In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on 
                                    </p>

                                    <p style="margin: 15px 30px; font-size: 20px;">
                                        mean used as a placeholder before final copy is available. Wikipedia
                                    </p>
                                </div>
                            </div>
                        </mdb-card-text>
                    </mdb-card-body>
                </mdb-card>
            </div>
        </div>
        <div class="col-md-3">
            <mdb-card class="mb-3">
                <mdb-card-body>
                    <mdb-card-text> 
                        <div><a style="padding: 7px 40px; background-color:#00356b !important; font-size: 15px; color:#fff;" class="btn btn-primary" role="button" data-mdb-toggle="button">Analytics</a></div>
                        <div style="display: flex; font-size:12px" class="mt-3">
                            <div style="margin-right:15px;font-weight: 550;"><p style="color:#00356B;">Total Views <span style="color: #4A4848; font-weight: 550;">100</span></p></div>
                            <div><p style="color:#00356B;font-weight: 550;">Total Downloads <span style="color: #4A4848; font-weight: 550;">76</span></p></div>
                        </div>

                        <div style="display: flex;">
                            <div><a style="padding: 5px 18px; background-color:#FAEBD9 !important; border: none; font-size: 13px; color:#B5861E;" class="btn btn-primary" role="button" data-mdb-toggle="button">View Open Resources</a></div>
                            <div style="margin-left:11%; padding-top:5px;color:#00356B;font-weight: 550;">176</div>
                        </div>

                        <div style="color:#00356B; font-weight:500" class="mt-4 activity">
                            <p>Books <span style="margin-left: 35%;">23</span></p>
                            <p>Journal articles <span style="margin-left: 10%;">07</span></p>
                            <p>Thesis/Disertations<span style="margin-left: 4%;">5</span></p>
                            <p>Presentations <span style="margin-left: 18%;">5</span></p>
                            <p>Lecture Notes <span  style="margin-left: 18%;">3</span></p>
                            <p>Lecture Videos<span  style="margin-left: 15%;">11</span></p>
                            <p>Courseware <span  style="margin-left: 20%;">14</span></p>
                            <p>Other OERs <span  style="margin-left: 22%;">2</span></p>
                        </div>

                        <div>
                            <div style="display: flex;">
                                <div style=" margin-right: 10%; color:#00356B;">share on</div>
                                <div style="display: flex;">
                                    <div class="mr-3"><i class="fab fa-facebook-square" style="color:#4460a0"></i></div>
                                    <div class="mr-3"><i class="fab fa-twitter-square" style="color:#1086c0"></i></div>
                                    <div><i class="fab fa-linkedin" style="color:#1086c0"></i></div>
                                </div>
                            </div>
                        </div>
                    </mdb-card-text>
                </mdb-card-body>
            </mdb-card> 
        </div>
    </div>

    
    <div>
        <div style="background-color: #F3F0ED;">
            <div style="color:#00356B;padding:20px; font-size:22px;">My Library</div>
        </div>
    
        <div class="row" style="margin-top:2%">
            <div class="col-md-6">
                <mdb-card class="mb-4">
                    <mdb-card-body>
                      <mdb-card-title>
                        <h4 class="card-text" style="color: #625E5E; font-size: 20px;">Implications for industrial application of bioflocculant demand alternatives to conventional media: waste as a substitute</h4>
                      </mdb-card-title>
                      <mdb-card-text> 
                        <span>By Mohammed, J.n., and Wan Dagang, W.r.z. </span>
                        <span class="journal-article py-2 px-2" style="margin-left:20px">Journal Articles</span>
                        </mdb-card-text>
                        <mdb-card-text style="color:#7A7A7A"> 
                        <i class="fas fa-eye" style="color:#B5861E"></i> 11.23k 
                          </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
            </div>
            <div class="col-md-6">
                <mdb-card class="mb-4">
                    <mdb-card-body>
                      <mdb-card-title>
                        <h4 class="card-text" style="color: #625E5E; font-size: 20px;">Implications for industrial application of bioflocculant demand alternatives to conventional media: waste as a substitute</h4>
                      </mdb-card-title>
                      <mdb-card-text> 
                        <span>By Mohammed, J.n., and Wan Dagang, W.r.z. </span>
                        <span class="journal-article py-2 px-2" style="margin-left:20px">Journal Articles</span>
                        </mdb-card-text>
                        <mdb-card-text style="color:#7A7A7A"> 
                        <i class="fas fa-eye" style="color:#B5861E"></i> 11.23k 
                          </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>   
            </div>
        </div>
    
        <div class="row" style="margin-top:2%; margin-bottom: 4%;">
            <div class="col-md-6">
                <mdb-card class="mb-4">
                    <mdb-card-body>
                      <mdb-card-title>
                        <h4 class="card-text" style="color: #625E5E; font-size: 20px;">Implications for industrial application of bioflocculant demand alternatives to conventional media: waste as a substitute</h4>
                      </mdb-card-title>
                      <mdb-card-text> 
                        <span>By Mohammed, J.n., and Wan Dagang, W.r.z. </span>
                        <span class="journal-article py-2 px-2" style="margin-left:20px">Journal Articles</span>
                        </mdb-card-text>
                        <mdb-card-text style="color:#7A7A7A"> 
                        <i class="fas fa-eye" style="color:#B5861E"></i> 11.23k 
                          </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
            </div>
            <div class="col-md-6">
                <mdb-card class="mb-4">
                    <mdb-card-body>
                      <mdb-card-title>
                        <h4 class="card-text" style="color: #625E5E; font-size: 20px;">Implications for industrial application of bioflocculant demand alternatives to conventional media: waste as a substitute</h4>
                      </mdb-card-title>
                      <mdb-card-text> 
                        <span style="font-size: 20px;">By Mohammed, J.n., and Wan Dagang, W.r.z. </span>
                        <span class="journal-article py-2 px-2" style="margin-left:20px">Journal Articles</span>
                        </mdb-card-text>
                        <mdb-card-text style="color:#7A7A7A"> 
                        <i class="fas fa-eye" style="color:#B5861E"></i> 11.23k 
                          </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
            </div>
        </div>
    </div>
</div>