import { Faculty } from './../models/faculty.model';



export class AddFaculty {
    static readonly type = '[Faculty] Add';
    constructor(public payload: { name: string; role: string; message: string }) {}
}
  
  export class GetFaculties {
    static readonly type = '[Faculty] Get';
}

export class EditFaculty {
  static readonly type = '[Faculty] Edit';
  constructor(public payload: { name: string; role: string; facultyID: string }) {}
}

export class DeleteFaculty {
  static readonly type = '[Faculty] Delete';
  constructor(public payload: { name: string; facultyID: string }) {}
}

export class AddDepartment {
  static readonly type = '[Department] Add';
  constructor(public payload: { name: string; faculty: string;  role:string;  }) {}
}

export class GetDepartments {
  static readonly type = '[Department] Get';
}

  