import { Upload } from 'src/app/shared/models/upload.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UploadService {
  
  endpoint: string = "https://mu-oer.herokuapp.com/api";
  single: string = "https://mu-oer.herokuapp.com/api/resource";
  headers = new HttpHeaders().set('Content-Type', 'application/json');
 


  constructor(
    private http: HttpClient,
  ) { }


  upload(upload){
    return this.http.post<any>(`${this.endpoint}/resource/store`,upload)
  }

  showUpload(resource){
    return this.http.post<any>(`${this.single}/show`, resource )
  }

  getUploads(): Observable<Upload[]>{
    return this.http.get<Upload[]>(`${this.endpoint}/resource`)
  }

  getOneResource(upload): Observable<any> {
    return this.http.post<any>(`${this.single}/show`,upload)
   }
}
