<!-- search bar -->
<div>
  <ul class="nav justify-content-center white lighten-4" style="padding-bottom: 3%;">
    <div class="search-div">

      <input class="border-right form-control form-control-sm mr-3 ml-5 w-75" type="text"
        placeholder="Search Library Resources" aria-label="Search" mdbInput>
      <div class="input-btn" style="float: right; margin-top: -40px; margin-right: 100px;">
        <button class="btn-search-all" mat-button>All</button><button class="btn-search-author"
          mat-button>Author</button><button class="btn-search-title" mat-button>Title</button>
      </div>

    </div>
    <mdb-icon class="search-ico" fas icon="search" aria-hidden="true"></mdb-icon>
  </ul>
</div>


<!-- News jumbotron -->
<div class="jumbotron text-center hoverable p-4" style="background-color:#F2ECEC;">

  <!-- Grid row -->
  <div class="row">

    <!-- Grid column -->
    <div class="col-md-4 mx-1 my-1">
      <mdb-card>
        <mdb-card-body>
          <!--Title-->
          <mdb-card-title class="open">
            <div style="margin: 0px !important; font-weight: bold; font-size: 17px;" class="card-heading">Open Education
              Resource Data Base &nbsp; &nbsp; <i class="fas fa-arrow-right"></i></div>
          </mdb-card-title>


        </mdb-card-body>
      </mdb-card>


      <div class="row mt-3 ml-1">
        <mdb-card class="col-md-5 mr-5">

          <mdb-card-body>

            <!--Title-->
            <mdb-card-title class="menu-1">
              <h4 style="width:100%;" class="card-text">Books</h4>
            </mdb-card-title>


          </mdb-card-body>
        </mdb-card>
        <mdb-card class="col-md-5">

          <mdb-card-body>

            <!--Title-->
            <mdb-card-title class="menu-2">
              <h4 style="margin: 0px !important;" class="card-text">Journal Articles</h4>
            </mdb-card-title>


          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="row mt-3 ml-1">
        <mdb-card class="col-md-5 mr-5">

          <mdb-card-body>

            <!--Title-->
            <mdb-card-title>
              <h4 style="margin: 0px !important;" class="card-text">Thesis/ Dissertations</h4>
            </mdb-card-title>


          </mdb-card-body>
        </mdb-card>
        <mdb-card class="col-md-5">

          <mdb-card-body>

            <!--Title-->
            <mdb-card-title class="menu-2">
              <h4 style="margin: 0px !important;" class="card-text">Conference Presentation</h4>
            </mdb-card-title>


          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="row mt-3 ml-1">
        <mdb-card class="col-md-5 mr-5">

          <mdb-card-body>

            <!--Title-->
            <mdb-card-title class="menu-3">
              <h4 style="margin: 0px !important;" class="card-text">Lecture Videos</h4>
            </mdb-card-title>


          </mdb-card-body>
        </mdb-card>
        <mdb-card class="col-md-5">

          <mdb-card-body>

            <!--Title-->
            <mdb-card-title>
              <h4 style="margin: 0px !important;" class="card-text">Lecture Notes</h4>
            </mdb-card-title>


          </mdb-card-body>
        </mdb-card>
      </div>



    </div>
    <!-- Grid column -->
    <div class="col-md-7 text-md-left ml-1 mt-1">
      <div class="d-flex" style="height:450px;">
        <!-- Card -->


        <!-- Card -->
        <mdb-card style="background-image: url('assets/images/oer-image.png');background-repeat: no-repeat; width:100%;"
          class="card-image mb-3 mx-2">
          <!-- Content -->
          <div class="text-white text-center d-flex align-items-center rgba-indigo-strong py-5 px-4"
            style="height: 450px;">
            <div class="welcome">
              <h3 class="card-title"><strong>Welcome to</strong></h3>
              <p>
                Tony G. Erewa College of Education OER.
              </p>
            </div>
          </div>
          <!-- Content -->
        </mdb-card>
        <!-- Card -->
      </div>


    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->

</div>
<!-- News jumbotron -->

<div class="res-container" style="margin-bottom:4%">
  <!--Grid row-->
  <div class="row pt-2">
    <div class="col-lg-7 first-row">
      <div class="card">
        <div class="card-header text-center" style="background-color: #b5861e;;">
          <div style="color:#fff; font-weight:550; font-size:20px">All Resources<span> <i
                class="fas fa-arrow-right float-right"></i></span></div>
        </div>
        <div class="card-body" style="background-color: #f2ecec;" *ngIf="resources$">
          <mdb-card class="mb-4" (click)="openResourceDetails(resource)" [routerLink]="['/single-resource']"
            *ngFor="let resource of resources$ | async">
            <mdb-card-body>
              <mdb-card-title>
                <h4 class="card-text"> {{ resource.title }}</h4>
              </mdb-card-title>
              <mdb-card-text>
                <span>{{ resource.author }}</span>
                <span class="journal-article py-2 px-2" style="margin-left:20px">{{ resource.category }}</span>
              </mdb-card-text>
              <mdb-card-text>
                <i class="fas fa-eye"></i> 11.23k
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
          <div style="float:right">more...</div>
        </div>
      </div>


    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-5 text-center text-lg-left">
      <!--Grid column-->
      <div class="jumbotron jumbotron-fluid" style="background-color:#F2ECEC;">
        <div class="container">
          <h1 class="display-4">Other Links <i class="fas fa-arrow-right float-right"></i></h1>
          <ul class="list-group list-group-flush">
            <li class="list-group-item mb-4">
              <a><span>Convocation Lectures Series</span> <i style="background-color:#F2ECEC ;"
                  class="fas fa-lightbulb float-right px-2 py-2 rounded-circle"></i></a>

            </li>
            <li class="list-group-item mb-4">
              <a><span>Key Note Addresses</span><i style="background-color:#F2ECEC ;"
                  class="fas fa-graduation-cap float-right px-2 py-2 rounded-circle"></i></a>

            </li>
            <li class="list-group-item mb-4">
              <a><span>Order of Proceedings</span><i style="background-color:#F2ECEC ;"
                  class="fas fa-book float-right px-2 py-2 rounded-circle"></i></a>

            </li>
            <li class="list-group-item mb-4">
              <a><span>Other Lecture Programmes</span><i style="background-color:#F2ECEC ;"
                  class="fas fa-book float-right px-2 py-2 rounded-circle"></i></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <h2 class="col-md-12">Faculties</h2>
        <div class="faculty col-md-4">Administration</div>
        <div class="faculty col-md-4">Agriculture</div>
        <div class="faculty col-md-4">Engineering</div>
        <div class="faculty col-md-4">Medicine</div>
        <div class="faculty col-md-4">Law</div>
        <div class="faculty col-md-4">Environmental</div>
        <div class="faculty col-md-4">Social Science</div>
        <div class="faculty col-md-4">Physical Science</div>
        <div class="faculty col-md-4">Arts & Humanities</div>
      </div>
    </div>
  </div>
</div>



<!--Section heading-->


<!--Grid row-->

<div class="row pt-2 ml-4 mr-4 mb-3">
  <h2 class="col-md-12 news-heading py-3">News</h2>
  <div class="card-deck">
    <!-- Card -->
    <mdb-card>
      <div class="view overlay waves-light" mdbWavesEffect>
        <!-- Card img -->
        <mdb-card-img class="img-fluid rounded" src="assets/images/Rectangle-30.png" alt="Card image cap">
        </mdb-card-img>
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>
      <!--Card content-->
      <mdb-card-body>
        <!--Title-->
        <mdb-card-title>
          <h4 style="color: #5F5959;">10th June 2020</h4>
        </mdb-card-title>

        <!--Text-->
        <mdb-card-text>
          <div style="color:#00356B; font-weight: 600;">Research on the output being conveyed on the conference
            materials under the supervision of the FG.</div>
        </mdb-card-text>

        <a style="color:#F97C7C;" class="float-right">read more...</a>
      </mdb-card-body>
    </mdb-card>

    <!-- Card -->
    <mdb-card>
      <div class="view rgba-white-slight waves-light" mdbWavesEffect>
        <!-- Card img -->
        <mdb-card-img src="assets/images/Rectangle-31.png" alt="Card image cap"></mdb-card-img>
        <a>
          <div class="mask"></div>
        </a>
      </div>
      <!--Card content-->
      <mdb-card-body>
        <!--Title-->
        <mdb-card-title>
          <h4 style="color: #5F5959;">10th June 2020</h4>
        </mdb-card-title>

        <!--Text-->
        <mdb-card-text>
          <div style="color:#00356B; font-weight: 600;">Research on the output being conveyed on the conference
            materials under the supervision of the FG.</div>
        </mdb-card-text>

        <a style="color:#F97C7C;" class="float-right">read more...</a>
      </mdb-card-body>
    </mdb-card>

    <!-- Card -->
    <mdb-card>
      <div class="view rgba-white-slight waves-light" mdbWavesEffect>
        <!-- Card img -->
        <mdb-card-img src="assets/images/Rectangle-32.png" alt="Card image cap"></mdb-card-img>
        <a>
          <div class="mask"></div>
        </a>
      </div>
      <!--Card content-->
      <mdb-card-body>
        <!--Title-->
        <mdb-card-title>
          <h4 style="color: #5F5959;">10th June 2020</h4>
        </mdb-card-title>

        <!--Text-->
        <mdb-card-text>
          <div style="color:#00356B; font-weight: 600;">Research on the output being conveyed on the conference
            materials under the supervision of the FG.</div>
        </mdb-card-text>

        <a style="color:#F97C7C;" class="float-right">read more...</a>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
<!--Grid row-->