import { Upload } from '../models/upload.model'



export class AddUpload {
    static readonly type = '[Upload] Add';
    constructor(public payload: Upload ) {}
  }
  
  export class GetUploads {
    static readonly type = '[Upload] Get';
  }
  