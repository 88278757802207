
<div style="text-align: center; margin: 15px 0px;">
  <div style="font-weight:500; font-size: 32px;">Uploaded Resources</div>
  <div style="margin-top:8px">You can buy books  from our publishing  stores</div>
</div>
<!-- search bar -->
<div>
  <ul class="nav justify-content-center white lighten-4" style="padding-bottom: 1%;">
    <div class="search-div">
       
        <input class="border-right form-control form-control-sm mr-3 ml-5 w-75" type="text" placeholder="Search Library Resources" aria-label="Search"
        mdbInput> 
        <div class="input-btn" style="float: right; margin-top: -40px; margin-right: 100px;">
            <button class="btn-search-all" mat-button>All</button><button class="btn-search-author" mat-button>Author</button><button class="btn-search-title" mat-button>Title</button>
        </div>

    </div>
    <mdb-icon class="search-ico" fas icon="search" aria-hidden="true"></mdb-icon>
  </ul> 
</div>

<!-- bottons -->
<div style="display: flex; margin-left:40px; margin-bottom:20px" class="m-search">
  <div style="color:#00356B; background-color: #f2ecec;; padding:5px 25px; margin-right:30px; border-radius: 40px; cursor:pointer">Author</div>
  <div style="color:#00356B; background-color: #f2ecec;; padding:5px 25px; margin-right:30px; border-radius: 40px;cursor: pointer;">Format</div>
  <div style="color:#00356B; background-color: #f2ecec;; padding:5px 25px; margin-right:30px; border-radius: 40px; cursor: pointer;">Language</div>
  <div style="color:#00356B; background-color: #f2ecec;; padding:5px 25px; margin-right:30px; border-radius: 40px; cursor: pointer;">book series</div>
</div>
<section>
     <div class="resource-container">
       <div class="row">
         <div class="col-md-4 small-cont">
           <div class="card text-center">
             <div class="card-header" style="background-color: #b5861e; color:#fff; font-weight:550; font-size:20px">Category</div>
             <div class="card-body" style="background-color: #f2ecec;">
               <h5 style="text-align: left; font-size:15px">Filter</h5>
               <mat-form-field appearance="fill">
                 <mat-label>Select Category</mat-label>
                <mat-select>
                  <mat-option *ngFor="let category of categories" [value]="category.value">
                    {{category.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
             </div>
           </div>
         </div>
   
         <div class="col-md-8" style="margin-bottom: 8%;" >
           <div class="card" *ngIf="uploads$">
             <div class="card-header text-center" style="background-color: #b5861e;" >
              <div style="color:#fff; font-weight:550; font-size:20px">All Resources<span> <i class="fas fa-arrow-right float-right"></i></span></div>
  
            </div>
              <div class="card-body" style="background-color: #f2ecec;" >
                <mdb-card class="mb-3" *ngFor="let upload of uploads$ | async">
                  <mdb-card-body (click)="openResourceDetails(upload)">
                    <mdb-card-title>
                      <h4 class="card-text" style="font-size: 20px;">{{ upload.title }}</h4>
                    </mdb-card-title>
                    <mdb-card-text> 
                      <span>{{ upload.author }}</span>
                      <span class="journal-article py-2 px-2" style="margin-left:20px">Journal Articles</span>
                      </mdb-card-text>
                      <mdb-card-text> 
                        <div style="display:flex">
                          <div style="display:flex; margin-right:3%; font-size: 13px;">
                            <div style="margin-right:6px; color:#b5861e">views</div>
                            <div>200</div>
                          </div>

                          <div style="display: flex; font-size: 13px;">
                            <div style="margin-right:6px; color:#b5861e">Downloads</div>
                            <div>76</div>
                          </div>
                        </div>
                      </mdb-card-text>
                    </mdb-card-body>
                  </mdb-card>
                  <div style="display: flex; justify-content: space-between; margin: 0px 26px">
                    <div style="font-size: 12px; color:#7A7A7A">1 of 300</div>
                    <div style="font-size: 12px; color:#7A7A7A"><button type="button" class="btn btn-light" data-mdb-ripple-color="dark">Next</button></div>
                  </div>
                </div>
           </div>
         </div>
       </div>

      </div>
</section>
