import { MatIconModule } from '@angular/material/icon';
import { OerHeaderComponent } from './../../shared/component/oer-header/oer-header.component';
import { OerFooterComponent } from './../../shared/component/oer-footer/oer-footer.component';
import { OnlineBookStoreSingleComponent } from './../../oer/online-book-store-single/online-book-store-single.component';
import { OerLoginComponent } from './../../oer/oer-login/oer-login.component';
import { OerHomeComponent } from './../../oer/oer-home/oer-home.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { OerComponent } from './oer.component';
import { OerRegisterComponent } from 'src/app/oer/oer-register/oer-register.component';
import { OnlineBookStoreComponent } from 'src/app/oer/online-book-store/online-book-store.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';



@NgModule({
  declarations: [
    OerComponent,
    OerHomeComponent,
    OerLoginComponent,
    OerRegisterComponent,
    OnlineBookStoreComponent,
    OnlineBookStoreSingleComponent,
    OerFooterComponent,
    OerHeaderComponent



  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSliderModule,
    MatTabsModule,
    MatIconModule,
    MatSidenavModule,
    RouterModule,
    MDBBootstrapModule.forRoot(),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class OerModule { }
