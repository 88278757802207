import { AdminDashboardModule } from './admin-dashboard/admin-dashboard.module';
import { FacultyState } from './shared/state/create';
import { UploadState } from './shared/state/upload.state';
import { GuestGuard } from './Middleware/guest.guard';
import { AuthGuard } from './Middleware/auth.guard';
import { AuthService } from './shared/services/auth/auth.service';
import {NgxsModule} from '@ngxs/store';
import { NgxsStoragePluginModule} from '@ngxs/storage-plugin'
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptorService } from './shared/services/auth/token-interceptor.service';
import { AuthState} from './shared/state/auth.state';
import { OerModule } from './layouts/oer/oer.module';
import { ProfileComponent } from './oer/profile/profile.component';
import { OerSingleResourceComponent } from './oer/oer-single-resource/oer-single-resource.component';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { IsLoggedIn } from './shared/utilities/is-logged-in';
import { ComponentModule } from './component/component.module';
import { DepartmentState } from './shared/state/department.state';
import { BookstoreComponent } from './oer/dashboard/bookstore/bookstore.component';
import { GuestResourceComponent } from './oer/guest-resource/guest-resource.component';
// import { NgxSpinnerModule } from "ngx-spinner";



@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    OerSingleResourceComponent,
    BookstoreComponent,
    GuestResourceComponent,
   
  
  ],
  imports: [
    BrowserModule,
    NgxsModule.forRoot([AuthState, UploadState, FacultyState, DepartmentState]),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token','auth.role','auth.email','auth.message']
    }),
    HttpClientModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastClass: 'toast toast-bootstrap-compatibility-fix'
    }),
    MDBBootstrapModule.forRoot(),
    ReactiveFormsModule,
    NgxUiLoaderHttpModule,
    // AdminDashboardModule,
    FormsModule,
    // NgxSpinnerModule, 
    NgxUiLoaderModule,
    MatCardModule,
    MatGridListModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatSidenavModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatTableModule,
    MatToolbarModule,
    MatTabsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,
    HttpClientModule,
    ComponentModule,

  ],
  providers: [
    FormsModule,
    ReactiveFormsModule,
    OerModule,
    IsLoggedIn,
    AuthService,
    AuthGuard, 
    GuestGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }     
  ],
  
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
