// import { UploadStateModel } from './upload.state';
import { Injectable } from "@angular/core";
import { Upload } from '../models/upload.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { AddUpload, GetUploads } from '../actions/upload.action';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UploadService } from '../services/upload/upload.service';

export class UploadStateModel {
    uploads!: Upload[];
    areUploadsLoaded!: boolean;
}

@State<UploadStateModel>({
    name: 'uploads',
    defaults: {
        uploads: [],
        areUploadsLoaded: false
    }
})

@Injectable()
export class UploadState {
    
    constructor(private uploadService: UploadService, private router: Router){
    
    }
    @Selector()
    static getUploads(state: UploadStateModel) {
        return state.uploads;
    }

    @Selector()
    static areUploadsLoaded(state: UploadStateModel){
        return state.areUploadsLoaded;
    }


    @Action(AddUpload)
        addUpload({getState, patchState}: StateContext<UploadStateModel>, {payload}: AddUpload) {
            return this.uploadService.upload(payload).pipe(tap((result) => {
                console.log(result)
                const state = getState();
                patchState({
                    uploads: [...state.uploads, result]
                });
            }));
        }

        @Action(GetUploads)
        getCourses({getState, setState}: StateContext<UploadStateModel>) {
        return this.uploadService.getUploads().pipe(
            tap(result => {
                // console.log(result)
            const state = getState();
            setState({
                ...state,
                uploads: result,
                areUploadsLoaded: true
            });
            })
        );
        }
}
