import { OerSingleResourceComponent } from './oer/oer-single-resource/oer-single-resource.component';
import { OerLoginComponent } from './oer/oer-login/oer-login.component';
import { OnlineBookStoreSingleComponent } from './oer/online-book-store-single/online-book-store-single.component';
import { OerHomeComponent } from './oer/oer-home/oer-home.component';
import { OerComponent } from './layouts/oer/oer.component';
import { GuestGuard } from './Middleware/guest.guard';
import { CommonModule } from '@angular/common';
import { NgModule, Component } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { OerRegisterComponent } from './oer/oer-register/oer-register.component';
import { ProfileComponent } from './oer/profile/profile.component';
import { UploadComponent } from './oer/dashboard/upload/upload.component';
import { AuthGuard } from './Middleware/auth.guard';
import { IsLoggedIn } from './shared/utilities/is-logged-in';
import { GuestResourceComponent } from './oer/guest-resource/guest-resource.component';
import { OnlineBookStoreComponent } from './oer/online-book-store/online-book-store.component';


const routes: Routes = [
  
 
// OER
  
  {
    path: '',
    component: OerComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      
      {
      
      path: 'home',
      component: OerHomeComponent,
      resolve: [IsLoggedIn]
     },

     {
      path: 'admin',
      loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
      canActivate: [AuthGuard]
     },
     
    {
      path: 'resources',
      component: GuestResourceComponent,
      resolve: [IsLoggedIn]
      
    },
    {
      path: 'online-book-store',
      component: OnlineBookStoreComponent,
      resolve: [IsLoggedIn]
    },
    {
      path: 'single-resource',
      component: OerSingleResourceComponent,
      resolve: [IsLoggedIn]
    },
    {
      path: 'online-book-store-single',
      component: OnlineBookStoreSingleComponent,
      resolve: [IsLoggedIn]
    },
    {
      path: 'register',
      component: OerRegisterComponent,
      resolve: [IsLoggedIn]
    },
    {
      path: 'login',
      component: OerLoginComponent,
      resolve: [IsLoggedIn]
    },
    // {
    //   path: 'dashboard',
    //   component: DashboardComponent,  
    //   canActivate: [AuthGuard]
    // },

    {
      path: 'profile',
      component: ProfileComponent,
      resolve: [IsLoggedIn]
    },

    // {
    //   path: 'upload',
    //   component: UploadComponent,
    //   resolve: [IsLoggedIn]
    // },

    { 
      path: 'Dashboard', 
      loadChildren: () => import('./oer/dashboard/dashboard.module').then(m => m.DashboardModule),
      canActivate: [AuthGuard]
    },

    {
      path: '**',
      redirectTo: 'home'
    }
  ]
  },
  
 

  
 

  
];

@NgModule({
  imports: [CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })],
  //   RouterModule.forRoot(routes)],
    // exports: [RouterModule]
    //     useHash: false
    // }),
  //   RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
