
<div class="single-container">
    <div style="border: 2px solid #f2ecec; padding: 30px; border-radius:10px">
        <h3 style="text-align: center; font-size:30px; line-height: 40px; color:#00356B; font-weight: 500;">
            {{ singleResource$.response.title }}
        </h3>
        <p [routerLink]="['/profile']" style="text-align:center;font-size:20px; color:#b5861e; font-weight:500; cursor: pointer;">{{singleResource$.response.author}}</p>
    </div>

    <div style="display:flex; margin-top:4%; justify-content: center; margin-bottom: 4%;">
        <a  style="padding: 7px 40px;margin-right: 40px; background-color:#00356b !important; color:#fff;" class="btn btn-primary" role="button" data-mdb-toggle="button">Read Online</a>
        <a  style="padding: 7px 40px; background-color:#00356b !important; color:#fff;" class="btn btn-primary" role="button" data-mdb-toggle="button">Download</a>
    </div>

    <div>
        <h3 style="color: #00356B; font-weight: 500; font: size 20px; margin-left: 30px;">Journal Articles</h3>

        <div style="border: 2px solid #f2ecec; padding-top: 30px; margin-bottom:3%">
            <h3 style="text-align: center; font-size:30px; color:#00356B;">
                Abstract
            </h3>
            <div style="margin: 4% 10%; line-height:30px; font-size: 20px;">
                <p style=" color: #7A7A7A;">
                    Be transported to the banks of the Seine, a corner boulangerie, or beneath the Eiffel 
                    Tower with these beautifully illustrated vignettes of life in the City of Light. What began as a
                     way to fund travel became ten years of a letter subscription service delivering thousands of
                      painted letters to subscribers who delight in fun mail!
                </p>
    
                <p style="color:#7A7A7A; padding-top:10px">
                    Eat, Pray, Love meets Claude Monet in this epistolary ode to
                     Paris. What started as a whim in a Latin Quarter café blossomed into 
                     Janice MacLeod’s yearslong endeavor to document and celebrate life in Paris,
                      sending monthly snippets of her paintings and writings to the mailboxes of ardent 
                      followers around the world. Now, Dear Paris collects the entirety of the Paris Letters project:
    
                </p>

            </div>

            <div style="background-color: #f9f5ea; color:#00356B">
                <div style="text-align: center; padding: 10px 0px; font-size:20px">
                    <p style="margin-bottom: 3px !important;">Formart:pdf</p>
                    <p style="margin-bottom: 3px !important;">Published: 20/02/1990</p>
                    <p style="margin-bottom: 3px !important;">size:2.3md</p>
                    <p style="margin-bottom: 3px !important;">Uploaded by: <span style="color:#b5861e">Mohammed</span></p>
                    <p style="margin-bottom: 3px !important;">Uploaded On: 30/09/1993</p>
                    <p style="margin-bottom: 3px !important;">Road: 1</p>
                    <p style="margin-bottom: 0px !important;">Download: 2</p>
                </div>
            </div>
        </div>
    </div>

    <div style="background-image: linear-gradient(to right, #B5861E,#011b82);">
        <div style="text-align: center; color:#fff;padding:20px; font-size:22px;">Related Resources</div>
    </div>

    <div class="row" style="margin-top:5%">
        <div class="col-md-6">
            <h3 style="color: #00356B; font-weight:500; font-size: 18px;">Implications for industrial application of bioflocculant demand alternatives to conventional media: waste as a substitute</h3>
            <p style="color:#625E5E; font-size: 18px;">By Mohammed, J.n., and Wan Dagang, W.r.z.</p>
        </div>
        <div class="col-md-6">
            <h3 style="color: #00356B; font-weight:500; font-size: 18px;">Implications for industrial application of bioflocculant demand alternatives to conventional media: waste as a substitute</h3>
            <p style="color:#625E5E; font-size: 18px;">By Mohammed, J.n., and Wan Dagang, W.r.z.</p>
        </div>
    </div>

    <div class="row" style="margin-top:5%; margin-bottom: 4%;">
        <div class="col-md-6">
            <h3 style="color: #00356B; font-weight:500; font-size: 18px;">Implications for industrial application of bioflocculant demand alternatives to conventional media: waste as a substitute</h3>
            <p style="color:#625E5E; font-size: 18px;">By Mohammed, J.n., and Wan Dagang, W.r.z.</p>
        </div>
        <div class="col-md-6">
            <h3 style="color: #00356B; font-weight:500; font-size: 18px;">Implications for industrial application of bioflocculant demand alternatives to conventional media: waste as a substitute</h3>
            <p style="color:#625E5E; font-size: 18px;">By Mohammed, J.n., and Wan Dagang, W.r.z.</p>
        </div>
    </div>
</div>