import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  menuLists = ['Biography', 'Membership', 'publications', 'Specialization', 'Research', 'Responsibility'];
  selectedList:any;

  constructor() { }

  ngOnInit(): void {
    this.selectedList = this.menuLists[0]
  }


  openMenuList(menuList){
    this.selectedList = menuList
  }

}
