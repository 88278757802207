import { AuthService } from './../services/auth/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class IsLoggedIn {

    constructor(
        private router: Router,
        private AuthService: AuthService
    ) {}

    resolve(): void {
        if(this.AuthService.isAuthenticated()) {
            this.router.navigate(['admin']);
        }
    }
}
