
export interface AuthStateModel {
  token: string | null;
  email: string | null;
  role: string | null;
  message: string | null
}


export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { _id: string; name: string; email: string; password: string; role: string; message: string }) {}
}

export class Register {
  static readonly type = '[Auth] Register';
  constructor(public payload: { _id: string; name: string; email: string; password: string; role: string; }) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}