import { DepartmentService } from './../services/department/department.service';
import { Department } from 'src/app/shared/models/department.model';
import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AddDepartment, GetDepartments } from '../actions/create';


export class DepartmentStateModel {
    departments!: Department[];
    areDepartmentsLoaded!: boolean;
}

@State<DepartmentStateModel>({
    name: 'departments',
    defaults: {
        departments: [],
        areDepartmentsLoaded: false,
    }
})
@Injectable()
export class DepartmentState {
    
    constructor(private departmentService: DepartmentService, private router: Router){
    
    }
    @Selector()
    static getDepartments(state: DepartmentStateModel) {
        return state.departments;
    }

    @Selector()
    static areDepartemntsLoaded(state: DepartmentStateModel){
        return state.areDepartmentsLoaded;
    }


    @Action(AddDepartment)
        add({getState, patchState}: StateContext<DepartmentStateModel>, {payload}: AddDepartment) {
            return this.departmentService.createDepartment(payload).pipe(tap((result) => {
                console.log(result)
                const state = getState();
                patchState({
                    departments: [...state.departments, result]
                });
            }),
            // catchError((err: HttpErrorResponse) => {
            //     alert("Something happened. Please try again.");
            //     return throwError(new Error(err.message));
            //   })
            
            
            );
        }

        @Action(GetDepartments)
        get({getState, setState}: StateContext<DepartmentStateModel>) {
        return this.departmentService.GetDepartments().pipe(
            tap(result => {
                console.log(result)
            const state = getState();
            setState({
                ...state,
                departments: result,
                areDepartmentsLoaded: true
            });
            })
        );
        }
}

