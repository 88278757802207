import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Department } from 'src/app/shared/models/department.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  endpoint: string = "https://mu-oer.herokuapp.com/api"



  constructor(
    private http: HttpClient
  ) { }



  createDepartment(department){
    return this.http.post<any>(`${this.endpoint}/department/store`, department)
  }
  
  
  GetDepartments(): Observable<Department[]>{
    return this.http.get<Department[]>(`${this.endpoint}/department`)
  }
}





