<div class="container pt-4 pb-3">
    <div class="row">
        <div class="col-md-6 offset-md-3">
            <!-- Material form login -->
<mdb-card class="mb-5">

    <!-- <mdb-card-header class="white-text text-center py-4">
     
    </mdb-card-header> -->
    <div class="text-center">
      <h2 style="font-size: 30px; padding-top: 20px">
        Registration
      </h2>
    </div>
  
    <!--Card content-->
    <mdb-card-body class="px-lg-5 pt-0">

      <!-- <form class="text-center" [formGroup]="signupForm" style="color: #0F0D3E;">
        <div class="form-outline">
          <label class="form-label" for="form1" style="float: left !important; font-weight: 500;">First Name</label>
          <input style="background-color: #f4f6f9;" type="text" id="form1" formControlName="firstname" class="form-control"  [ngClass]="{ 'is-invalid': submitted && formControl.firstname.errors}" />
          <div *ngIf="submitted && formControl.firstname.errors" class="invalid-feedback">
            <div *ngIf="formControl.firstname.errors.required">Name is required</div>
        </div>
        </div>

        <div class="form-outline">
          <label class="form-label" for="form1" style="float: left !important; font-weight: 500; margin-top:4%">Last Name</label>
          <input style="background-color: #f4f6f9;" type="text" id="form1" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.lastname.errors}" />
          <div *ngIf="submitted && formControl.lastname.errors" class="invalid-feedback">
            <div *ngIf="formControl.lastname.errors.required">Name is required</div>
        </div>
        </div>

        <div class="form-outline">
          <label class="form-label" for="form1" style="float: left !important; font-weight: 500; margin-top:4%"  [ngClass]="{ 'is-invalid': submitted && formControl.email.errors}">Email Address</label>
          <input style="background-color: #f4f6f9;" type="email" id="form1" formControlName="email" class="form-control" />
          <div *ngIf="submitted && formControl.email.errors" class="invalid-feedback">
            <div *ngIf="formControl.email.errors.required">Email is required</div>
            <div *ngIf="formControl.email.errors.email">Email must be a valid email address</div>
        </div>
        </div>
       
        <div class="form-outline">
          <label class="form-label" for="form1" style="float: left !important; font-weight: 500; margin-top:4%">Password</label>
          <input style="background-color: #f4f6f9;" type="password" id="form1" formControlName="password" class="form-control" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.password.errors }" />
          <div *ngIf="submitted && formControl.password.errors" class="invalid-feedback">
            <div *ngIf="formControl.password.errors.required">Password is required</div>
        </div>
        </div>


        <div class="form-outline">
          <label class="form-label" for="form1" style="float: left !important; font-weight: 500; margin-top:4%">Confirm Password</label>
          <input style="background-color: #f4f6f9;" type="password" id="form1" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.confirmPassword.errors }" />
          <div *ngIf="submitted && formControl.confirmPassword.errors" class="invalid-feedback">
            <div *ngIf="formControl.confirmPassword.errors.required">Confirm Password is required</div>
            <div *ngIf="formControl.confirmPassword.errors.mustMatch">Passwords must match</div>
        </div>
        </div>
  
        <button mdbBtn class="custom-submit my-4 waves-effect z-depth-0" mdbWavesEffect  (click)="registerUser()">Register</button>
      </form> -->

      <form [formGroup]="signupForm" (ngSubmit)="registerUser()">
        <div class="form-group">
            <label>First Name</label>
            <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && signupForm.get('firstname')?.invalid}" />
            <div *ngIf="submitted && signupForm.get('firstName')?.invalid" class="invalid-feedback">
                <div  *ngIf="signupForm.get('firstname')?.hasError('required')">First Name is required</div>
            </div>
        </div>
        <div class="form-group">
            <label>Last Name</label>
            <input type="text" class="form-control"  formControlName="lastName"  [ngClass]="{ 'is-invalid': submitted && signupForm.get('lastName')?.invalid}"  />
            <div *ngIf="submitted && signupForm.get('lastName')?.invalid" class="invalid-feedback">
                <div *ngIf="signupForm.get('lastName')?.hasError('required')">Last Name is required</div>
            </div>
        </div>
        <div class="form-group">
            <label>Email</label>
            <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && signupForm.get('email')?.invalid}" />
            <div *ngIf="submitted && signupForm.get('email')?.invalid" class="invalid-feedback">
                <div *ngIf="signupForm.get('email')?.hasError('required')">Email is required</div>
                <div *ngIf="signupForm.get('email')?.hasError('required')">Email must be a valid email address</div>
            </div>
        </div>
        <div class="form-group">
            <label>Password</label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && signupForm.get('password')?.invalid}" />
            <div *ngIf="submitted && signupForm.get('password')?.invalid" class="invalid-feedback">
                <div *ngIf="signupForm.get('password')?.hasError('required')">Password is required</div>
                <div  *ngIf="signupForm.get('password')?.hasError('minlength')">Password must be at least 6 characters</div>
            </div>
        </div>
        <div class="form-group">
            <label>Confirm Password</label>
            <input type="password"  formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && signupForm.get('confirmPassword')?.invalid}" />
            <div *ngIf="submitted && formControl.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="signupForm.get('confirmPassword')?.hasError('required')">Confirm Password is required</div>
                <div *ngIf="signupForm.get('confirmPassword')?.hasError('mustMatch')">Passwords must match</div>
            </div>
        </div>
        <div class="form-group">
            <button class="btn btn custom-submit">Register</button>
        </div>
    </form>


      <div style="display: flex; justify-content: center;">
        <p class="mr-4" style="font-weight: 500;">Already a member?</p>
        <div> <a  [routerLink]="[ '/login']">Login</a></div>
      </div>
      <!-- Form -->
  
    </mdb-card-body>
  
  </mdb-card>
  <!-- Material form login -->
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" type="ball-8bits">  
  <p style="color: white">Please Wait. </p>  
</ngx-spinner>
