<style>
    .nav-item-link {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        display: flex;
        align-items: center;

        color: #49c723 !important;

    }
</style>
<div style="margin-bottom:10px;" *ngIf="router.url !== '/admin/home' && router.url !== '/admin/user'
  && router.url !== '/admin/dashboard-resources'  && router.url !== '/admin/submission'
  && router.url !== '/admin/faculty'  && router.url !== '/admin/analytics'
  && router.url !== '/admin/setting'  && router.url !== '/admin/administrator'
  && router.url !== '/admin/news'  && router.url !== '/admin/positions' 
  && router.url !== '/Dashboard/home'  && router.url !== '/landing' 
  && router.url !== '/Dashboard/user-resource'  && router.url !== '/Dashboard/upload'">



    <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light white sticky-top" [containerInside]="false">
        <mdb-navbar-brand>
            <a class="navbar-brand" [routerLink]="[ 'home']">
                <img src="assets/images/logo/erewa-logo.png" height="60" class="d-inline-block" alt="">
                <span class="logo-text">Tony G. Erewa COE </span>
            </a>
        </mdb-navbar-brand>
        <links class="justify-content-center">
            <ul class="navbar-nav">
                <li class="nav-item-link">
                    <a class="" [routerLink]="[ '/resources']" class="nav-link waves-light"
                        mdbWavesEffect>Resources<span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item-link">
                    <a [routerLink]="[ '_#']" class="nav-link waves-light" mdbWavesEffect>Plagirism Checker</a>
                </li>
                <li class="nav-item-link">
                    <div class="dropdown">
                        <button class="btn btn dropdown-toggle dropdown-m"
                            style="color: #00356B !important; font-size:17px;  font-weight: 400;" type="button"
                            id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                            F<span class="m-caps">aculty</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <li><button class="dropdown-item" type="button">College of Food Technology</button></li>
                            <li><button class="dropdown-item" type="button">College of Science</button></li>
                            <li><button class="dropdown-item" type="button">Agriculture Management</button></li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item-link">
                    <a [routerLink]="[ '/online-book-store']" class="nav-link waves-light">Book Store</a>
                </li>
                <li class="login-btn">
                    <a [routerLink]="[ '/login']" class="nav-link waves-light">Login</a>
                </li>

                <li class="register-btn">
                    <a [routerLink]="[ '/register']" class="nav-link waves-light">Register</a>
                </li>
            </ul>
        </links>
    </mdb-navbar>
</div>
<!-- <ul class="nav justify-content-center white lighten-4" style="padding-bottom: 3%;">
        <div class="search-div">
           
            <input class="border-right form-control form-control-sm mt-3 mr-3 ml-5 w-75" type="text" placeholder="Search Library Resources" aria-label="Search"
            mdbInput> 
            <div class="input-btn" style="float: right; margin-top: -40px; margin-right: 100px;">
                <button class="btn-search-all" mat-button>All</button><button class="btn-search-author" mat-button>Author</button><button class="btn-search-title" mat-button>Title</button>
            </div>

        </div>
        <mdb-icon class="search-ico" fas icon="search" aria-hidden="true"></mdb-icon>
    </ul> -->