import { Login, Logout, Register, } from 'src/app/shared/actions/auth.action';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AuthStateModel} from "../actions/auth.action";
import { AuthService } from "../services/auth/auth.service";
import {tap} from 'rxjs/operators';
import { NotificationService } from '../services/notification/notification.service';




@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    email: null,
    role: null,
    message:null
  }
})


@Injectable()
export class AuthState {
    @Selector()
    static token(state: AuthStateModel): any {
      return state.token;
    }
  
    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
      return !!state.token;
    }


    @Selector()
    static currentUser(state: AuthStateModel) {
      return {
        email: state.email,
        token: state.token,
        role: state.role
      };
    }
  
    constructor(
      private authService: AuthService,
      private _router: Router,
      private notification: NotificationService,
  
    ) {
    
    }
  
    @Action(Login)
    signin(ctx: StateContext<AuthStateModel>, action: Login) {
      return this.authService.signIn(action.payload).pipe(
        tap((result:any) => {
          console.log(result)
          const state = ctx.getState();
          ctx.patchState({
            token: result.token,
            email: result.user.email,
            role: result.user.role,
            message: result.message
          });
        },err =>{
          console.log(err.result.message)
        })
      );
    }


    
    @Action(Register)
    signUp(ctx: StateContext<AuthStateModel>, action: Login) {
      return this.authService.signUp(action.payload).pipe(
        tap((result: any) => {
          // console.log(result)
          ctx.patchState({
            email: result.email
          });
        })
      );
    }
  
    @Action(Logout)
    logout({ setState, getState }: StateContext<AuthStateModel>) {
        this._router.navigate(['/login']);
        // const { token } = getState();
        setState({
            token: null,
            email: null,
            role: null,
            message: null
        });
    }

}