import { UploadService } from 'src/app/shared/services/upload/upload.service';

import { GetUploads } from './../../shared/actions/upload.action';
import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Upload } from 'src/app/shared/models/upload.model'
import { MessengerService } from 'src/app/shared/services/messenger/messenger.service';


@Component({
  selector: 'app-oer-home',
  templateUrl: './oer-home.component.html',
  styleUrls: ['./oer-home.component.scss']
})
export class OerHomeComponent implements OnInit {
  id
  resources$: Observable<Upload[]>

  constructor(
    private store: Store,
    private uploadService: UploadService,
    private messengerService: MessengerService
  ) { 
    this.resources$ = this.store.select(state => state.uploads.uploads.response);

  }

  ngOnInit(): void {
    this.store.dispatch(new GetUploads());
  }

  openResourceDetails(upload){
    this.id = upload._id
    let data;
    data = {
      resourceID: this.id 
    }
    this.uploadService.getOneResource(data).subscribe(info =>{
      console.log(info)
      this.messengerService.send.next(info)
      localStorage.setItem('info', info.response)
    })
    console.log(upload._id)
}

}
