<!-- Footer -->
<footer class="page-footer font-small"
  *ngIf="router.url !== '/login' && router.url !== '/register'
  && router.url !== '/admin/home' && router.url !== '/admin/user'
  && router.url !== '/admin/dashboard-resources'  && router.url !== '/admin/submission'
  && router.url !== '/admin/faculty'  && router.url !== '/admin/analytics'
  && router.url !== '/admin/setting'  && router.url !== '/admin/administrator'
  && router.url !== '/admin/news'  && router.url !== '/admin/positions' 
  && router.url !== '/Dashboard/home' && router.url !== '/Dashboard/user-resource' && router.url !== '/Dashboard/upload'">

  <!-- Footer Links -->
  <div class="container text-center text-md-left">
    <div class="row">
      <div class="col-sm-2">
        <div style="display:flex; margin: 20px 0px">
          <div><img style="width:50px; height: auto;" src="assets/images/logo/erewa-logo.png" alt=""></div>
          <div>
            <div style="font-size: 12px; margin-left:5px;">Tony G. Erewa </div>
            <div style="font-size: 12px; margin-left: 11px;">College of Education</div>
          </div>

        </div>
      </div>

      <div class="col-sm-3" style="margin:30px 0px">
        <div style="font-weight:bold;">
          <h3>Contacts</h3>
        </div>
        <div>
          <div style="font-size: 13px; margin-bottom:10px">Phone: <span style="margin-left:8%">+2341234567890</span>
          </div>
          <div style="font-size: 13px; margin-bottom:10px">Email: <span style="margin-left:8%">enquiry@tonygerewacoe.com.ng</span>
          </div>
          <div style="font-size: 13px; margin-bottom:10px">Address: <span>Sapele<br>Delta State
              Nigeria</span></div>
        </div>
      </div>

      <div class="col-sm-2" style="margin:30px 0px">
        <div style="font-weight:bold;">
          <h3>Other Links</h3>
        </div>
        <div>
          <div style="font-size: 13px; margin-bottom:10px"><a href="home">Oer Policy</a></div>
          <div style="font-size: 13px; margin-bottom:10px"><a href="home">Respository</a></div>
          <div style="font-size: 13px; margin-bottom:10px"><a href="home">NCCE Directory</a></div>
          <div style="font-size: 13px; margin-bottom:10px"><a href="home">Respository</a></div>
        </div>
      </div>

      <div class="col-sm-5" style="margin:30px 0px">
        <div style="font-weight:bold;">
          <h3>Social Network</h3>
        </div>
        <div style="display: flex;">
          <div style="font-size: 33px; margin-bottom:10px; margin-right:30px;">
            <img src="assets/images/logo/facebook.png" alt="">
          </div>
          <div style="font-size: 33px; margin-bottom:10px; margin-right:30px">
            <img src="assets/images/logo/insta.png" alt="">
          </div>
          <div style="font-size: 33px; margin-bottom:10px; ">
            <img src="assets/images/logo/twitter.png" alt="">
          </div>
          <div style="font-size: 33px; margin-bottom:10px; margin-right:30px">
            <img src="assets/images/logo/linkdln.png" alt="">
          </div>
          <div style="font-size: 33px; margin-bottom:10px;">
            <img src="assets/images/logo/google-.png" alt="">
          </div>
        </div>
      </div>

    </div>

  </div>

  <div>
    <div style="display:flex; width:100%; margin-top: 20px; border-top: 1px solid rgb(216, 210, 210);">
      <div style="padding:20px; font-size:13px">Privacy Policy</div>
    </div>
  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <!-- <div class="footer-copyright text-center py-3"> &copy;
      {{test | date: 'yyyy'}} Copyright:
    <a href="https://madukauniversity.com.ng/"> Maduka University</a>
  </div> -->
  <!-- Copyright -->

</footer>
<!-- Footer -->